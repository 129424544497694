import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    icon: <FaArrowCircleRight />,
    PointPara: '⟶',
    ParaEighteen:
      'In today’s current economy, one of the most critical ways to achieve an effective spend management strategy is via Sourcing. But what does this really mean?  Sourcing is literally going out to market and acquiring the goods and services you need to run your company, by partnering with the right suppliers and negotiating optimal contracts to continue growing your business. In order to do this, companies invest in technologies to deliver the necessary capabilities to drive an efficient external spend strategy, and therefore, essentially becoming a margin growth driver inside your company. This is where Raindrop comes in. When it comes to managing the outflow of money, a large degree of companies either have no, or more likely they have outdated, inadequate, and fragmented systems, which unsurprisingly and consequently leads to spend leakage.',
    ImagePathOne:
      'https://storage.googleapis.com/raindroppublic/website_data/Recession%20Proof%20your%20Sourcing%20Strategy%20internal%20image%201.oojpg.jpg',
    ParaOneLeft: 'Source -',
    ParaOneAnchor: 'McKinsey',
    ParaOneRight: '',
    ShiftUrlParaOne:
      'https://www.mckinsey.com/capabilities/operations/our-insights/full-potential-procurement-lessons-amid-inflation-and-volatility',
    ParaTwoBefor: '',
    ParaTwoAnchor: 'Raindrop’s e-Sourcing module',
    ParaTwoAfter:
      'is a key component in reducing your corporate expense run rate by quickly and easily taking your supplier spend out to market, and creating cost compression by increasing the competition for your money in a challenging economy. Since cost considerations are a vital driver for every company, Raindrop helps reduce your costs and expand your margin.',
    AnchorLink: 'https://raindrop.com/solutions/SourcingEvents',
    ParaThree:
      'Raindrop recognizes the critical requirement of e-Sourcing technologies and the gap in the current solution space, especially as it relates to companies who need to take their spend competitively out to market, but may not have the technology solutions available to support their needs. Raindrop’s e-Sourcing module is a highly intuitive and easy-to-use solution, designed to take your business out to market quickly and efficiently. Gain the desired results in a compressed amount of time with Raindrop’s streamlined and digitized process. Raindrop helps to take your request out to a global or domestic supplier base in just a few clicks. Define your scope, invite vetted bidders, and the intuitive platform will run and manage the entire bidding process, resulting in competitive bids and analysis from your qualified suppliers for the desired goods and services.',
    ParaFour:
      'Raindrop allows quick, confident, and seamless interactions for better communication, speed to realizing value, and delivering process compliance using the following steps:',
    ParaFive: '1. Drafting the RFP',
    ParaSix: '2. Collaborating with Internal teams',
    ParaSeven: '3. Inviting vetted suppliers to the RFP',
    ParaEight: '4. Qualifying suppliers for the bid',
    ParaNine: '5. Addressing Q&A through a centralized portal',
    ParaTen: '6. Evaluating the bids by providing fingertip-ready bid analytics',
    ImagePathTwo:
      'https://storage.googleapis.com/raindroppublic/website_data/Recession%20Proof%20your%20Sourcing%20Strategy%20internal%20image.jpg',
    HeadingOne: 'Let’s have a look at Raindrop’s Value Proposition',
    HeadingOnePointOne: 'Reduce go-to-market time',
    ParaEleven:
      'Increase sourcing efficiency and save time by 75% with Raindrop’s ready to use template libraries. Drafting and going out to market with Raindrop is just a few clicks away.',
    HeadingOnePointTwo: 'Drive savings',
    ParaTwelve:
      'Configure supplier-side feedback at the item level, or at the lot level, in terms of how far their price is from the lowest bid, or from the historical price. Raindrop provides instant feedback to bidders on their quotes, creating an atmosphere designed to drive price compression. Gain insights on the system-delivered savings qualification, between quotes as well as between sourcing rounds.',
    HeadingOnePointThree: 'Mitigate risks & manage compliance',
    ParaThirteen:
      'Move from guesswork to assured decision-making with intelligent insights from Raindrop’s AI-powered platform. Gain rapid insight into bidder quality, ability to reliably fulfil and support the requirements, compliance certifications, and much more.',
    HeadingOnePointFour: 'Seamless internal and external collaboration',
    ParaFourteen:
      'The built-in collaboration feature enables you to chat internally as well as with your suppliers during the sourcing event. Move quickly and confidently with communications all in a single location.  Hold bidders to due dates, and let the system provide reminders to follow up.',
    HeadingOnePointFive: 'Operational efficiency',
    ParaFifteen:
      'After rapid and successful sourcing and award, you can seamlessly pass the contract documents into Raindrop’s Contract Module, fulfilling your entire Procure-to-Pay needs in a single system. Seamless, rapid, and fully integrated, from Source-to-Pay.',
    HeadingOnePointSix: 'Powerful evaluation',
    ParaSixteen:
      'Raindrop’s pre-built framework acts as a centrepiece to evaluate suppliers based on price, coverage, outliers, and incumbent vs. new supplier award optimization based on several parameters.',
    HeadingTwo: 'Why Raindrop?',
    ParaSeventeen:
      'Raindrop’s e-Sourcing module is rapid to access, simple, intuitive, and easy to use. It allows you to quickly gain desired results in a compressed amount of time with AI-powered digitized process. Raindrop helps to take your request out to a global or domestic supplier base in just a few clicks and helps you take the best decisions at all times. In a challenging economy, this capability can be priceless.',
  },
]
